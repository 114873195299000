import React, { useMemo } from 'react';
import Layout from '@components/layout';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Section from '@atoms/Section/Section';
import CMSHeaderMinimal from '@components/HeaderMinimal/HeaderMinimalV2';
import Title from '@atoms/Title/Title';
import Module from '@atoms/Module/Module';
import Seo from '@components/seo';
import Avatar from '@components/Avatar/Avatar';
import { ChapterUSP, CommonActionButton } from '@components/CMSComponents';
import Footer from '@components/Footer/Footer';
import Text from '@atoms/Text/Text';
import MarkdownText from '@atoms/MarkdownText/MarkdownText';
import './Location.scss';
import LanguageNotSupported from '@components/LanguageNotSupported';
import useLocale from '@hooks/useLocale';
import { getOriginalLocale } from '@helpers/GlobalHelpers';
import { ProviderList, RelatedContentWithImg } from '../../CMSComponents';
import { withLocale } from '@hocs';
import OnlineOrOnSite from '../../CMSComponents/OnlineOrOnSite';
import CoveragePricingShortInfo from '@components/CoveragePricingShortInfo';
import { Box } from '@material-ui/core';
import CardGrid from '../../CardGrid/CardGrid';
import CardWithBackground from '../../Card/CardWithBackground';
import {
    UserLang,
    PROVIDER_AREA_TRANSLATION_KEY,
    Gender,
    FunnelQuoteType,
} from '@constants/common';
import { useTranslation } from 'react-i18next';
import {
    getCity,
    hasOnlineSessionOffered,
    getProviderAudioUrl,
} from '@helpers/GlobalHelpers';

const LOCATION_QUERY_PARAM = '&providerAreas=ZURICH&locationType=ALL';

const LocationPage: React.FC<any> = ({
    data: graphqlData,
    pageContext: { pageData },
}) => {
    const { t } = useTranslation();
    const { currentLanguage } = useLocale();

    const currentLocale = getOriginalLocale(pageData?.attributes.locale);

    const cmsData = useMemo(() => {
        if (!pageData || (pageData && currentLocale !== currentLanguage)) {
            return null;
        }

        const {
            attributes: { header, usp, explanation },
            attributes,
        } = pageData;

        const headerDesktopImage = header?.images.find(
            (item: any) => item.media === 'desktop'
        );
        const headerMobileImage = header?.images.find(
            (item: any) => item.media === 'mobile'
        );

        const uspData = usp.data?.attributes?.component;

        const explanationData = explanation.data?.attributes?.component;

        return {
            ...attributes,
            usp: uspData && uspData.length > 0 ? uspData[0] : null,
            explanation:
                explanationData && explanationData.length > 0
                    ? explanationData[0]
                    : null,
            headerDesktopImage,
            headerMobileImage,
        };
    }, [pageData, currentLanguage]);

    const getDataByLang = (currentLanguage, graphqlData) => {
        switch (currentLanguage) {
            case UserLang.English:
                return graphqlData.aepsyApiEn.providers;
            case UserLang.Italian:
                return graphqlData.aepsyApiIt.providers;
            case UserLang.French:
                return graphqlData.aepsyApiFr.providers;
            case UserLang.German:
            default:
                return graphqlData.aepsyApiDe.providers;
        }
    };

    const buildLocationDescriptionString = (locationInfo) => {
        const fullAddress =
            locationInfo?.locations && locationInfo?.locations.length > 0
                ? locationInfo?.locations[0]?.fullAddress
                : undefined;
        const city = getCity(locationInfo);
        const area =
            locationInfo.areas && locationInfo.areas.length > 0
                ? locationInfo.areas[0]
                : null;
        const onlineSessionOffered = hasOnlineSessionOffered(locationInfo);

        if (area == null) {
            return 'Online';
        } else {
            const translatedArea = t(PROVIDER_AREA_TRANSLATION_KEY[area]);
            const location = fullAddress
                ? fullAddress
                : city
                ? `${city} - ${translatedArea}`
                : `${translatedArea}`;

            const locationString =
                location.length > 30
                    ? location.substring(0, 27) + '...'
                    : location;

            if (onlineSessionOffered) {
                return locationString + ' & Online';
            }

            return locationString;
        }
    };

    const mappingProviderItem = (item: any) => {
        const isMale = item.userInfo.gender === Gender.Male;

        const quoteType = item.profile.quoteTypes.includes(
            FunnelQuoteType.Therapy
        )
            ? FunnelQuoteType.Therapy
            : FunnelQuoteType.Coach;

        return {
            avatar: {
                src: {
                    data: {
                        attributes: {
                            url: item.userInfo.avatar,
                        },
                    },
                },
            },
            audio: {
                data: {
                    attributes: {
                        url: getProviderAudioUrl(
                            item.profile.providerInfo,
                            currentLanguage
                        ),
                    },
                },
            },
            badgeColor: 'red',
            badgeLabel: t(
                quoteType === FunnelQuoteType.Therapy
                    ? isMale
                        ? 'provider.phase.psychotherapist.male'
                        : 'provider.phase.psychotherapist.female'
                    : isMale
                    ? 'provider.phase.psychologist.male'
                    : 'provider.phase.psychologist.female'
            ),
            name: (
                <Title size="ml" noMargin align="center">
                    {item.userName.firstName + ' ' + item.userName.lastName}
                </Title>
            ),
            yearsOfExp: buildLocationDescriptionString(
                item.profile.locationInfo
            ),
        };
    };

    const providersSlider = useMemo(() => {
        if (!pageData || (pageData && currentLocale !== currentLanguage)) {
            return null;
        }

        const {
            attributes: { providersSlider },
        } = pageData;

        const data = getDataByLang(currentLanguage, graphqlData);

        return {
            title: providersSlider?.title,
            subtitle: providersSlider?.subtitle,
            providerList: data.map((item) => mappingProviderItem(item)),
        };
    }, [graphqlData, pageData, currentLanguage]);

    if (!pageData || !cmsData || !cmsData?.header) {
        return (
            <Layout>
                <div className="global_theme-green">
                    <HeaderNav theme="dark" />
                    <Section spacingBottom="m">
                        <LanguageNotSupported />
                    </Section>
                    <Footer />
                </div>
            </Layout>
        );
    }

    const {
        headerDesktopImage,
        headerMobileImage,
        header,
        usp,
        onlineOrOnSite,
        relatedContent,
        seo,
        providerQuoteSecond,
        providerQuoteFirst,
        explanation,
        primaryCta,
    } = cmsData;

    return (
        <Layout>
            <Seo title={seo?.title} description={seo?.description} />
            <div className="global_theme-green Chapter">
                <HeaderNav theme="white" />
                <Section theme="light">
                    <CMSHeaderMinimal
                        desktopImage={
                            headerDesktopImage
                                ? headerDesktopImage.src.data.attributes.url
                                : undefined
                        }
                        mobileImage={
                            headerMobileImage
                                ? headerMobileImage.src.data.attributes.url
                                : undefined
                        }
                        title={header.title}
                        titleSize="xxl"
                        spacing="l"
                        btnPrimary={
                            <CommonActionButton
                                cta={{
                                    ctaLabel: header.ctaLabel,
                                    ctaUrl: header.ctaUrl,
                                }}
                            />
                        }
                        callToScroll
                    />
                </Section>
                <div
                    id={
                        header?.secondaryCtaUrl?.includes('#')
                            ? header?.secondaryCtaUrl?.replace('#', '')
                            : 'ChapterPage'
                    }
                >
                    {onlineOrOnSite.data && (
                        <Section spacingBottom="m">
                            <OnlineOrOnSite
                                data={
                                    onlineOrOnSite.data.attributes.component[0]
                                }
                            />
                        </Section>
                    )}

                    <Section theme="dark">
                        {providerQuoteFirst && (
                            <div className="LocationProviderQuoteFirst">
                                <Section container="short">
                                    <Module radius="xl">
                                        <Title size="xl" align="center">
                                            "{providerQuoteFirst.quote}"
                                        </Title>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <Section container="short">
                                                <div className="LocationProviderQuoteFirst--provider">
                                                    <Avatar
                                                        size="s"
                                                        src={
                                                            providerQuoteFirst
                                                                .avatar?.src
                                                                ?.data
                                                                ?.attributes
                                                                ?.url
                                                        }
                                                    />
                                                    <div className="LocationProviderQuoteFirst--providerInfo">
                                                        <Title
                                                            size="m"
                                                            theme="lighter"
                                                        >
                                                            {
                                                                providerQuoteFirst.name
                                                            }
                                                            ,{' '}
                                                            {
                                                                providerQuoteFirst.yearsOfExp
                                                            }
                                                        </Title>
                                                        <Text size="s">
                                                            {
                                                                providerQuoteFirst.badgeLabel
                                                            }
                                                        </Text>
                                                    </div>
                                                </div>
                                            </Section>
                                        </Box>
                                    </Module>
                                </Section>
                            </div>
                        )}
                        {providersSlider && (
                            <ProviderList theme="white" {...providersSlider} />
                        )}

                        {primaryCta && (
                            <div className="LocationPrimacyCta">
                                <CommonActionButton
                                    cta={{
                                        ctaUrl: `${primaryCta.to}/${LOCATION_QUERY_PARAM}`,
                                        ctaLabel: primaryCta.label,
                                    }}
                                />
                            </div>
                        )}
                    </Section>

                    {explanation && (
                        <Section
                            container="large"
                            spacingBottom="s"
                            className="LocationExplanation"
                        >
                            <CardGrid size="2">
                                <div>
                                    <Title>{explanation.title}</Title>
                                    <MarkdownText
                                        content={explanation.content}
                                    />
                                </div>

                                <CardWithBackground
                                    url={
                                        explanation.image
                                            ? explanation.image.data.attributes
                                                  .url
                                            : undefined
                                    }
                                    greenBackground
                                />
                            </CardGrid>
                        </Section>
                    )}

                    {providerQuoteSecond && (
                        <Section container="short" spacingTop="m">
                            <Title size="xl" align="center">
                                "{providerQuoteSecond.quote}"
                            </Title>
                            <Title size="m" align="center" theme="lighter">
                                {providerQuoteSecond.name},{' '}
                                {providerQuoteSecond.yearsOfExp}
                            </Title>
                            <Text size="s" align="center">
                                {providerQuoteSecond.badgeLabel}
                            </Text>
                        </Section>
                    )}

                    {usp && (
                        <Section spacingTop="m" spacingBottom="m">
                            <ChapterUSP
                                uspList={usp.list}
                                cta={{
                                    ctaLabel: usp.ctaLabel,
                                    ctaUrl: usp.ctaUrl,
                                }}
                                title={usp.title}
                                queryParam={LOCATION_QUERY_PARAM}
                            />
                        </Section>
                    )}

                    <CoveragePricingShortInfo
                        queryParam={LOCATION_QUERY_PARAM}
                    />

                    {relatedContent && (
                        <RelatedContentWithImg {...relatedContent} />
                    )}
                </div>

                <Footer />
            </div>
        </Layout>
    );
};

export default withLocale(LocationPage);
