import { graphql } from 'gatsby';
import Location from './Location';
import React from 'react';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        aepsyApiDe {
            providers(
                input: {
                    isLive: true
                    size: 20
                    quoteTypes: [THERAPY]
                    providerAreas: [ZURICH]
                }
            ) {
                userInfo {
                    firebaseUid
                    avatar
                    gender
                }
                userName {
                    firstName
                    lastName
                }
                profile {
                    slug
                    quoteTypes
                    providerInfo {
                        providerTitle
                        audioMultiLangInfo {
                            audioUrls {
                                locale
                                content
                            }
                        }
                    }
                    locationInfo {
                        coachInPersonSessionOffered
                        areas
                        onlineSessionOffered
                        locations {
                            fullAddress
                            city
                            latitude
                            longitude
                        }
                    }
                }
            }
        }
        aepsyApiEn {
            providers(
                input: {
                    isLive: true
                    size: 20
                    quoteTypes: [THERAPY]
                    providerAreas: [ZURICH]
                }
            ) {
                userInfo {
                    firebaseUid
                    avatar
                    gender
                }
                userName {
                    firstName
                    lastName
                }
                profile {
                    slug
                    quoteTypes
                    providerInfo {
                        providerTitle
                        audioMultiLangInfo {
                            audioUrls {
                                locale
                                content
                            }
                        }
                    }
                    locationInfo {
                        coachInPersonSessionOffered
                        areas
                        onlineSessionOffered
                        locations {
                            fullAddress
                            city
                            latitude
                            longitude
                        }
                    }
                }
            }
        }
        aepsyApiFr {
            providers(
                input: {
                    isLive: true
                    size: 20
                    quoteTypes: [THERAPY]
                    providerAreas: [ZURICH]
                }
            ) {
                userInfo {
                    firebaseUid
                    avatar
                    gender
                }
                userName {
                    firstName
                    lastName
                }
                profile {
                    slug
                    quoteTypes
                    providerInfo {
                        providerTitle
                        audioMultiLangInfo {
                            audioUrls {
                                locale
                                content
                            }
                        }
                    }
                    locationInfo {
                        coachInPersonSessionOffered
                        areas
                        onlineSessionOffered
                        locations {
                            fullAddress
                            city
                            latitude
                            longitude
                        }
                    }
                }
            }
        }
        aepsyApiIt {
            providers(
                input: {
                    isLive: true
                    size: 20
                    quoteTypes: [THERAPY]
                    providerAreas: [ZURICH]
                }
            ) {
                userInfo {
                    firebaseUid
                    avatar
                    gender
                }
                userName {
                    firstName
                    lastName
                }
                profile {
                    slug
                    quoteTypes
                    providerInfo {
                        providerTitle
                        audioMultiLangInfo {
                            audioUrls {
                                locale
                                content
                            }
                        }
                    }
                    locationInfo {
                        coachInPersonSessionOffered
                        areas
                        onlineSessionOffered
                        locations {
                            fullAddress
                            city
                            latitude
                            longitude
                        }
                    }
                }
            }
        }
    }
`;

const LocationTherapyZurich: React.FC<any> = (data) => {
    return <Location {...data} />;
};
export default LocationTherapyZurich;
